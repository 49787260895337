import { translate } from '@/messages'

export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('appeal.full_name'),
    align: 'start',
    value: 'full_name'
  },
  {
    text: translate('appeal.appeal_date'),
    align: 'start',
    value: 'appeal_date'
  }
]
